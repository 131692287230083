import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
} from "@apollo/client"
import { RestLink } from "apollo-link-rest"
import { onError } from "apollo-link-error"
import React /*, {useEffect}*/ from "react"
import fetch from "isomorphic-fetch"
import queryString from "query-string"
import { navigate } from "gatsby"

import { setToken, getToken /*, getJwtToken*/ } from "@src/utils/auth"
import config from "@src/config"

import {setContext} from '@apollo/client/link/context';

import { 
    //useMsal,
    //MsalContext
    //useAccount
    //useIsAuthenticated,
    //useMsalAuthentication
 } from "@azure/msal-react";
import { 
  //InteractionType,
  PublicClientApplication,
 }
 from "@azure/msal-browser";
import { 
  //getB2cConfig,
  //b2cPolicies, 
  //msalConfig, 
  getB2cSilentRequest,
  msalConfig
} from "@src/msalConfig";
import Constants from '../constants';
import axios from 'axios';

// catch errors
const errorLink = onError(
  ({ graphQLErrors, networkError, response, operation }) => {
    console.log("operation", operation)
    console.log("response", response)
    console.log("graphQLErrors", graphQLErrors)
    console.log("networkError", networkError)
    if (graphQLErrors) {
      if (typeof graphQLErrors === "object" && !Array.isArray(graphQLErrors)) {
        Object.values(graphQLErrors).forEach(val =>
          console.log(`[GraphQL error]: Message: ${val}`)
        )
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
      if ("statusCode" in networkError) {
        switch (networkError.statusCode) {
          case 401:
            navigate("/unauthorizedContent?error=401")
            break
        }
      }
    }
  }
)

const authRestLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }: Record<string, any>) => {
    console.log(`authRestLink START: ${JSON.stringify(headers)}`)
    // save session id in local storage
    const sid = getToken()
    if (sid) {
      setToken(sid as string)
      console.log(`authRestLink sessionID: ${sid}`)
    }
    else {
      const parsed = queryString.parse(
        typeof window !== `undefined` ? window.location.search : ""
      )
      if (parsed && parsed.SessionID) {
        setToken(parsed.SessionID as string)
      }
    }

    // add session id to headers
    return {
      headers: {
        ...headers,
        SessionID: getToken(),
      },
    }
  })

  return forward(operation)
})


const formSerializer = (data: { [key: string]: any }, headers: Headers) => {
  const formData = new FormData()
  for (const key in data) {
    // eslint-disable-next-line no-prototype-builtins
    if (data.hasOwnProperty(key)) {
      formData.append(key, data[key])
    }
  }
  // headers.set("Content-Type", "multipart/form-data")
  headers.set("Accept", "*/*")
  return { body: formData, headers }
}


// Set `RestLink` with your endpoint
// https://www.apollographql.com/docs/react/api/link/apollo-link-rest/#complete-options


const restLink = new RestLink({
  uri: config.api_base_url,
  //customFetch: fetch,
  customFetch: fetch,
  headers: {},
  bodySerializers: {
    formData: formSerializer,
    // fileEncode: (data: any, headers: Headers) => {
    //   const formData = new FormData()
    //   formData.append("file", data, data.name)
    //   headers.set("Accept", "*/*")
    //   return { body: formData, headers }
    // },
  },
})

const withJwtTokenLink = setContext(async(_,{headers}) =>{
  let token = null;
  const queryParams = new URLSearchParams(window.location.search);
  const authExperience = queryParams.get("authExperience")??"default";
  const loginRequest = getB2cSilentRequest("", authExperience);
  console.log(`withJwtTokenLink loginRequest: ${JSON.stringify(loginRequest)}`)
  //hooks are not allowed here ... 
  //const { /*instance,*/ accounts } = useMsal();
  const msalInstance = new PublicClientApplication(msalConfig);
  //const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance?.getAllAccounts();
  try {
  
  //const account = useAccount(accounts[0]);
  console.log(`withJwtTokenLink START: ${JSON.stringify(headers)}`)
  

  token = null;
  if (accounts && accounts[0]) {
    const account = accounts[0];
    console.log(`withJwtTokenLink account: ${JSON.stringify(accounts[0])}`)
    try {
      const response = await msalInstance?.acquireTokenSilent({
        ...loginRequest,
        account,
      });
      console.log(`withJwtTokenLink token response: ${JSON.stringify(response)}`)
      token = response?.idToken;
    } 
    catch (err) {
      console.log(`withJwtTokenLink err: ${JSON.stringify(err)}`)
    }
  }
  if (token) {
      // Get Session ID from API
      const url = `${process.env.REACT_APP_AUTHENTICATION_API}/loginUsingB2CTokenReturnSessionID`;
      const config = { headers: {'Content-Type': 'application/json'} }
      const res = await axios.post(url, '"'+token+'"', config);
      const sid = res.data;
      if (sid) {
        localStorage.setItem(Constants.sessionID, sid)
        setToken(sid as string)
        console.log(`useEffect [account]: sessionID ${JSON.stringify(sid)} `)
      }
  }
}
catch (err) {
  console.log(`withJwtTokenLink top err: ${JSON.stringify(err)}`)
}
  return{
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : null
    }
  }
});


// Setup your client
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([
    withJwtTokenLink,
    authRestLink,
    (errorLink as unknown) as ApolloLink,
    restLink,
  ]),
})

//todo: what to do with Apollo chache  ???
// useEffect(() => {
//   if(!isAuthenticated){
//     (async () => {
//       await client.resetStore() //clear Apollo cache when user loggs off
//     })()
//   }
// },[isAuthenticated]) 


interface IProps {
  children: React.ReactElement
}

const RootApolloProvider = ({ children }: IProps): React.ReactElement => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default RootApolloProvider
