// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-campaigns-tsx": () => import("./../../../src/pages/campaigns.tsx" /* webpackChunkName: "component---src-pages-campaigns-tsx" */),
  "component---src-pages-create-tsx": () => import("./../../../src/pages/create.tsx" /* webpackChunkName: "component---src-pages-create-tsx" */),
  "component---src-pages-creator-tsx": () => import("./../../../src/pages/creator.tsx" /* webpackChunkName: "component---src-pages-creator-tsx" */),
  "component---src-pages-edit-tsx": () => import("./../../../src/pages/edit.tsx" /* webpackChunkName: "component---src-pages-edit-tsx" */),
  "component---src-pages-help-tsx": () => import("./../../../src/pages/help.tsx" /* webpackChunkName: "component---src-pages-help-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-templates-tsx": () => import("./../../../src/pages/templates.tsx" /* webpackChunkName: "component---src-pages-templates-tsx" */),
  "component---src-pages-unauthorized-content-tsx": () => import("./../../../src/pages/unauthorizedContent.tsx" /* webpackChunkName: "component---src-pages-unauthorized-content-tsx" */)
}

