/*  eslint-disable */
import React from "react";
// import { PublicClientApplication} from "@azure/msal-browser";
// import { MsalProvider } from "@azure/msal-react";
// import { msalConfig } from "./src/msalConfig";
// import MsalEnabledApolloProvider from "./src/apolloClient/MsalEnabledApolloProvider"
import RootApolloProvider from "./src/apolloClient/ApolloProvider";

export const wrapRootElement = ({ element }) => {
  return <RootApolloProvider>{element}</RootApolloProvider>
}

// export const wrapPageElement = ({ element }) => {
//   const msalInstance = new PublicClientApplication(msalConfig);
//   return (
//     <MsalProvider instance={msalInstance}>
//       <MsalEnabledApolloProvider >
//       { element }
//       </MsalEnabledApolloProvider>
//     </MsalProvider>
//   )
// }


// export const wrapPageElement = ({ element }) => {
//   const msalInstance = new PublicClientApplication(msalConfig);
//   return (
//     <MsalProvider instance={msalInstance}>
//       { element }
//     </MsalProvider>
//   )
// }
