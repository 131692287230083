import { PublicClientApplication} from "@azure/msal-browser";
import { msalConfig } from "@src/msalConfig";
// import React from "react";
// import { 
//   InteractionType

//  } from "@azure/msal-browser";
// import { 
//    useMsal,
// } from "@azure/msal-react";
// import { getB2cConfig, b2cPolicies } from "@src/msalConfig";
const sessionIdKey = "postal-session-id"
const isBrowser = typeof window !== "undefined"

export const setToken = (token: string): void => {
  isBrowser && localStorage.setItem(sessionIdKey, token)
}

export const getToken = (): string | null => {
  return isBrowser ? localStorage.getItem(sessionIdKey) : null
}

export const setJwtToken = (token: string): void => {
  isBrowser && localStorage.setItem("jwtToken", token)
}

export const removeJwtToken = (): void => {
  isBrowser && localStorage.removeItem("jwtToken")
}

export const getJwtToken = (): string | null => {
  return isBrowser ? localStorage.getItem("jwtToken") : null
}

export const logout = async (): Promise<void> => {
  isBrowser && localStorage.removeItem(sessionIdKey)
  isBrowser && localStorage.removeItem("jwtToken");
  const instance = new PublicClientApplication(msalConfig);
  const logoutRequest = {
    account: instance.getActiveAccount()
  }
  await instance.logoutRedirect(logoutRequest);

}


// export function useFetchMsalTokenAndUserCustom(): {result:string, loading: boolean} {
//   const [result, setResult] = React.useState("");
//   const [loading, setLoading] = React.useState(false);
//   const { instance, accounts } = useMsal();
//   const loginRequest = getB2cConfig(b2cPolicies.names.signIn);
//   React.useEffect(() => {
//     setLoading(true);
//     if (accounts && accounts[0]) {
//       instance.acquireTokenSilent(loginRequest) 
//       .then ( (authResult) => {
//           console.log(`msalAuth.acquireToken: authResult ${JSON.stringify(authResult)}`)
//           if (authResult)
//           {
//             isBrowser && setJwtToken(authResult.accessToken);
//             setResult(authResult.accessToken);
//             setLoading(false);
//           } else {
//             isBrowser && removeJwtToken();
//           }      
//       });
//     }
//   }, [accounts])
//   return {result, loading};   
// }
